<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页设置<span>&gt;</span>
			管理页脚
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-row style="margin:10px;">
				<el-button type="primary" icon="el-icon-plus" @click="addFooter()">增加页脚</el-button>
			</el-row>
			<el-tabs type="border-card" class="footer-tab">
				<el-tab-pane label="左侧">
					<el-table :data="leftFooterList" stripe style="width: 100%">
						<el-table-column prop="label" label="名称" width="100"> </el-table-column>
						<el-table-column prop="target_url" label="跳转链接" width="180"> </el-table-column>
						<el-table-column label="新页面打开" width="80">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.new_page" type="success">是</el-tag>
								<el-tag v-else type="info">否</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="weight" label="权重" width="80"> </el-table-column>
						<el-table-column prop="update_time" label="更新时间" width="180"> </el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button type="primary" icon="el-icon-edit" circle @click="editFooter(scope.row.id)"></el-button>
								<el-button type="danger" icon="el-icon-delete" circle @click="deleteFooter(scope.row.id)"></el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="中间">
					<el-table :data="middleFooterList" stripe style="width: 100%">
						<el-table-column prop="label" label="名称" width="100"> </el-table-column>
						<el-table-column prop="target_url" label="跳转链接" width="180"> </el-table-column>
						<el-table-column label="新页面打开" width="80">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.new_page" type="success">是</el-tag>
								<el-tag v-else type="info">否</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="weight" label="权重" width="80"> </el-table-column>
						<el-table-column prop="update_time" label="更新时间" width="180"> </el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button type="primary" icon="el-icon-edit" circle @click="editFooter(scope.row.id)"></el-button>
								<el-button type="danger" icon="el-icon-delete" circle @click="deleteFooter(scope.row.id)"></el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="右侧">
					<el-table :data="rightFooterList" stripe style="width: 100%">
						<el-table-column prop="label" label="名称" width="100"> </el-table-column>
						<el-table-column prop="target_url" label="跳转链接" width="180"> </el-table-column>
						<el-table-column label="新页面打开" width="80">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.new_page" type="success">是</el-tag>
								<el-tag v-else type="info">否</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="weight" label="权重" width="80"> </el-table-column>
						<el-table-column prop="update_time" label="更新时间" width="180"> </el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button type="primary" icon="el-icon-edit" circle @click="editFooter(scope.row.id)"></el-button>
								<el-button type="danger" icon="el-icon-delete" circle @click="deleteFooter(scope.row.id)"></el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import { apiListFooterByCondition, apiDeleteFooter } from "@/api/admin/footer.js";

export default {
	data() {
		return {
			condition: { status: "" },
			leftFooterList: [],
			middleFooterList: [],
			rightFooterList: []
		};
	},
	methods: {
		// 根据条件列出页脚
		async listFooter() {
			try {
				let formData = new FormData();
				formData.append("status", this.condition.status);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiListFooterByCondition(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					let footerList = response.data.data;
					let leftFooterList = [];
					let middleFooterList = [];
					let rightFooterList = [];
					//分配页脚 给 对应的类型的页脚列表
					for (let i = 0; i < footerList.length; i++) {
						if (footerList[i].type == "left") {
							leftFooterList[leftFooterList.length] = footerList[i];
						} else if (footerList[i].type == "middle") {
							middleFooterList[middleFooterList.length] = footerList[i];
						} else {
							rightFooterList[rightFooterList.length] = footerList[i];
						}
					}
					this.leftFooterList = leftFooterList;
					this.middleFooterList = middleFooterList;
					this.rightFooterList = rightFooterList;
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		addFooter() {
			this.$router.push({ path: "/pc/admin/footer/add" });
		},
		editFooter(id) {
			window.location.href = "/pc/admin/footer/edit?id=" + id;
		},
		// 删除轮播图
		async deleteFooter(id) {
			try {
				let formData = new FormData();
				formData.append("id", id);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiDeleteFooter(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.$message({ message: "删除成功！", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listFooter();
	}
};
</script>

<style lang="scss" scoped>
.footer-tab {
	margin: 0px 10px;
}
</style>
